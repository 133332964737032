<script setup lang="ts">
import { type PropType, computed, type ComputedRef } from 'vue'
import { type Link } from '../../../interfaces/menu'

const props = defineProps({
    listLinks: {
        type: Array as PropType<Link[]>,
        required: true
    },
    authorized: {
        type: Boolean,
        default: true
    },
    isBig: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['closeMobileMenu'])

const linksAuthorized: ComputedRef<Link[]> = computed(() =>
    props.listLinks.filter(
        (link: any) => link.authorized === undefined || link.authorized === true
    )
)
</script>

<template>
    <div v-show="authorized" class="w-full" data-testId="menu-section">
        <div class="flex flex-col gap-1">
            <template v-for="link in linksAuthorized">
                <ChargemapMenuLink
                    v-if="!link.linksSubMenu"
                    :key="link.id"
                    :link="link"
                    :is-big="isBig"
                    @closeMobileMenu="emits('closeMobileMenu')"
                />
            </template>
        </div>
    </div>
</template>
